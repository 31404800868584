var domain;
if (process.env.REACT_APP_ENV === "development") {
    domain = "http://localhost:3000";
} else if (process.env.REACT_APP_ENV === "uat") {
    domain = "https://dev-api.alldernow.com";
} else {
    domain = "https://api.alldernow.com";
}
export { domain };
export var apiUrl = "/api/v1";
export var YES = "YES";
export var NO = "NO";
export var OK = "OK";
export var TOKEN = "TOKEN";
export var VER = "1.0.39";
export var endPoint = {
    LOGIN_URL: "".concat(apiUrl, "/auth/login"),
    VERIFY_ROLE_LOG: "".concat(apiUrl, "/verify-role-log"),
    NEW_ALLDERNOW_USER: "".concat(apiUrl, "/new-alldernow-user"),
    USER_INFO: "".concat(apiUrl, "/new-alldernow-user/get-user-info"),
    MST_PREFIX_URL: "".concat(apiUrl, "/mst-prefix"),
    MST_SEX_URL: "".concat(apiUrl, "/mst-sex"),
    MST_MARITAL_STATUS: "".concat(apiUrl, "/mst-marital-status"),
    MST_MILITARY_STATUS: "".concat(apiUrl, "/mst-military-status"),
    MST_RELATIONSHIP: "".concat(apiUrl, "/mst-relationship"),
    MST_EMPLOYEE_TYPE: "".concat(apiUrl, "/mst-employee-type"),
    MST_DEPARTMENT: "".concat(apiUrl, "/mst-department"),
    MST_POSITION: "".concat(apiUrl, "/mst-position"),
    MST_BANK: "".concat(apiUrl, "/mst-bank"),
    MST_BANK_ACCOUNT_TYPE: "".concat(apiUrl, "/mst-bank-account-type"),
    MST_EMPLOYEE_DOC: "".concat(apiUrl, "/mst-employee-doc"),
    MST_BRANCH: "".concat(apiUrl, "/mst-branch"),
    MST_BUSINESS_TYPE: "".concat(apiUrl, "/mst-business-type"),
    MST_BUSINESS_CATEGORY: "".concat(apiUrl, "/mst-business-category"),
    MST_COMPANY_PREFIX: "".concat(apiUrl, "/mst-company-prefix"),
    MST_PAYMENT_METHOD: "".concat(apiUrl, "/mst-payment-method"),
    MST_PAYMENT_TERM: "".concat(apiUrl, "/mst-payment-term"),
    MST_SUPPLIER_DOC: "".concat(apiUrl, "/mst-supplier-doc"),
    MST_NEW_ALLDERNOW_ROLE: "".concat(apiUrl, "/mst-new-alldernow-role"),
    MST_PAYMENT_TYPE: "".concat(apiUrl, "/mst-payment-type"),
    MST_DISCOUNT_TYPE: "".concat(apiUrl, "/mst-discount-type"),
    MST_PRICE_CATEGORY: "".concat(apiUrl, "/mst-price-category"),
    MST_VAT: "".concat(apiUrl, "/mst-vat"),
    WAREHOUSE: "".concat(apiUrl, "/warehouse"),
    MASTER_BRAND: "".concat(apiUrl, "/master-brand"),
    MASTER_MAIN_CATEGORY: "".concat(apiUrl, "/master-main-category"),
    MST_NEW_ITEM_PR_COL: "".concat(apiUrl, "/mst-new-item-pr-col"),
    NEW_ALLDERNOW_EMP: "".concat(apiUrl, "/new-alldernow-emp"),
    GROUP_OF_SUPPLIER: "".concat(apiUrl, "/group-of-supplier"),
    SUPPLIER_INFORMATION: "".concat(apiUrl, "/supplier-information"),
    PRODUCT_PRICE: "".concat(apiUrl, "/product-price"),
    NEW_PURCHASE_REQ: "".concat(apiUrl, "/new-purchase-req"),
    NEW_PURCHASE_ORDER: "".concat(apiUrl, "/new-purchase-order"),
    NEW_PO_SPLIT: "".concat(apiUrl, "/new-po-split"),
    NEW_GR: "".concat(apiUrl, "/new-gr"),
    HISTORY_BRANCH_ADDRESS: "".concat(apiUrl, "/history-branch-address")
};
