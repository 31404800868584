import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import logger from "redux-logger";
import rootReducer from "../slices";
var store = configureStore({
    middleware: function(getDefaultMiddleware) {
        var middleware = getDefaultMiddleware({
            serializableCheck: false
        });
        if (process.env.REACT_APP_ENV === "development") {
            middleware.push(logger);
        }
        return middleware;
    },
    reducer: rootReducer
});
var useAppDispatch = function() {
    return useDispatch();
};
var useAppSelector = function(selector, equalityFn) {
    return useSelector(selector, equalityFn);
};
export { store, useAppDispatch, useAppSelector };
